import React, {useState, useEffect} from "react";
import {Tooltip} from 'bootstrap/dist/js/bootstrap.esm.min';
import SignalTime from "./SignalTime";
import SignalName from "./SignalName";
import SignalChange from "./SignalChange";
import SignalAnomaly from "./SignalAnomaly";
import SignalPrice from "./SignalPrice";
import SignalMetrics from "./SignalMetrics";
import SignalSecurity from "./SignalSecurity";
import SignalInitialLiquidity from "./SignalInitialLiquidity";
import Errors from "./Errors";
import infoIcon from '../icons/info.svg';


function WhaleSignalsTable({bot}) {
    const [status, setStatus] = useState('loading')
    const [whaleSignals, setWhaleSignals] = useState([])
    const [signalSpentTime, setSignalSpentTime] = useState({})


    const refreshWhaleSignals = () => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/signals`)
            .then(resp => resp.json())
            .then(signals => {
                setWhaleSignals(signals)
                setStatus('ok')
            })
            .catch(_ => setStatus('error'));
    }

    useEffect(() => {
        refreshWhaleSignals()
        const intervalId = setInterval(refreshWhaleSignals, 5000)
        return () => clearInterval(intervalId)
    }, [])

    useEffect(() => {
        const refreshSpentTime = () => {
            if (!whaleSignals || !whaleSignals.length) {
                setSignalSpentTime({})
                return
            }

            const curTime = new Date().getTime()
            const newSignalSpentTime = whaleSignals.reduce((result, signal) => {
                result[signal.liquidity.pairAddress] = {
                    firstWhaleAt: (curTime - new Date(signal.firstWhaleAt).getTime()),
                    lastUpdateAt: (curTime - new Date(signal.updatedAt).getTime()),
                }
                return result
            }, {})

            setSignalSpentTime(newSignalSpentTime)
        }
        refreshSpentTime()
        const intervalId = setInterval(refreshSpentTime, 900)
        return () => clearInterval(intervalId)
    }, [whaleSignals])


    useEffect(() => {
        const tooltipTriggers = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltips = tooltipTriggers.map(el => {
            const tooltip = new Tooltip(el);
            if (el.matches(':hover')) {
                tooltip.show();
            }
            return tooltip;
        });

        return () => {
            tooltips.forEach(tooltip => tooltip.dispose());
        };
    }, [whaleSignals]);


    return (
        <>
            <div className="content py-10 d-none d-lg-flex">
                <div className="h-100 w-100 overflow-x-auto rounded-16">
                    <div className="d-flex flex-column flex-fill w-100 overflow-x-auto">
                        {/*<div  style={{ color: '#fff' }}>*/}
                        {/*    <pre>{JSON.stringify(whaleSignals[0], null, 2)}</pre>*/}
                        {/*</div>*/}

                        <div>
                            <table className="table table-hover table-whale">
                                <thead>
                                <tr>
                                    <th scope="col" width="206">Token</th>
                                    <th scope="col" width="194">Metrics</th>
                                    <th scope="col" width="190">Time</th>
                                    <th scope="col" width="200">Security</th>
                                    <th scope="col" width="144">Change</th>
                                    <th scope="col" width="144">Anomaly&nbsp;
                                        <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                                              data-bs-html="true" data-bs-title="
                                          <img src='/icons/emoji/whale.svg' width='12' /> big players <br>
                                          <img src='/icons/emoji/star.svg' width='12' /> influencer wallets <br>
                                          <img src='/icons/emoji/leaf.svg' width='12' /> new holders <br>
                                          <p>Quick growth in a young token <br>
                                          can indicate insider trading.</p>
                                      ">
                                            <img src={infoIcon} alt={'Info'} width='16'/>
                                        </span>
                                    </th>
                                    <th scope="col" width="144">Initial Liquidity</th>
                                    <th scope="col">Price</th>
                                </tr>
                                </thead>

                                {status === 'ok' && !!whaleSignals.length &&
                                    <tbody>
                                    {whaleSignals.map((signal, key) =>
                                        <tr key={key}>
                                            <td>
                                                <SignalName signal={signal} bot={bot}/>
                                            </td>
                                            <td>
                                                <SignalMetrics signal={signal}/>
                                            </td>
                                            <td>
                                                <SignalTime signal={signal} signalSpentTime={signalSpentTime}/>
                                            </td>
                                            <td>
                                                <SignalSecurity signal={signal}/>
                                            </td>
                                            <td>
                                                <SignalChange signal={signal}/>
                                            </td>
                                            <td>
                                                <SignalAnomaly signal={signal}/>
                                            </td>
                                            <td>
                                                <SignalInitialLiquidity signal={signal}/>
                                            </td>
                                            <td>
                                                <SignalPrice signal={signal}/>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                }

                            </table>
                        </div>

                        <Errors status={status} whaleSignalsLength={whaleSignals.length}/>
                    </div>
                </div>
            </div>

            {status === 'ok' && !!whaleSignals.length && (
                <>
                    <div className="my-10 d-none d-md-block d-lg-none">
                        <div className="row g-5">
                            {whaleSignals.map((signal, key) =>
                                <div className={`col-md-${whaleSignals.length === 1 ? 12 : 6}`} key={key}>
                                    <div className="main-background pt-6 pb-4 px-4 rounded-16">
                                        <SignalName signal={signal} bot={bot}/>
                                        <SignalChange signal={signal}/>
                                        <SignalMetrics signal={signal}/>
                                        <div className="row g-md-4">
                                            <div className="col-md-6">
                                                <SignalInitialLiquidity signal={signal}/>
                                            </div>
                                            <div className="col-md-6">
                                                <SignalTime signal={signal} signalSpentTime={signalSpentTime}/>
                                            </div>
                                        </div>

                                        <div className="row g-md-4">
                                            <div className="col-md-6">
                                                <SignalSecurity signal={signal}/>
                                            </div>
                                            <div className="col-md-6">
                                                <SignalAnomaly signal={signal}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-6 mb-10 d-none d-sm-block d-md-none">
                        <div className="row g-5">
                            {whaleSignals.map((signal, key) =>
                                <div className="col-md-12" key={key}>
                                    <div className="main-background pt-6 pb-4 px-4 rounded-16">
                                        <SignalName signal={signal} bot={bot}/>
                                        <div className="row g-4">
                                            <div className="col-6">
                                                <SignalChange signal={signal}/>
                                            </div>
                                            <div className="col-6">
                                                <SignalMetrics signal={signal}/>
                                            </div>
                                        </div>
                                        <div className="row g-4">
                                            <div className="col-6">
                                                <SignalInitialLiquidity signal={signal}/>
                                            </div>
                                            <div className="col-6">
                                                <SignalTime signal={signal} signalSpentTime={signalSpentTime}/>
                                            </div>
                                        </div>

                                        <div className="row g-4">
                                            <div className="col-6">
                                                <SignalSecurity signal={signal}/>
                                            </div>
                                            <div className="col-6">
                                                <SignalAnomaly signal={signal}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="mt-4 mb-6 d-block d-sm-none">
                        <div className="row g-5">
                            {whaleSignals.map((signal, key) =>
                                <div className="col-md-12" key={key}>
                                    <div className="main-background py-4 px-4 rounded-16">
                                        <SignalName signal={signal} bot={bot}/>

                                        <SignalChange signal={signal}/>

                                        <SignalMetrics signal={signal}/>

                                        <SignalInitialLiquidity signal={signal}/>

                                        <SignalTime signal={signal} signalSpentTime={signalSpentTime}/>

                                        <SignalAnomaly signal={signal}/>

                                        <SignalSecurity signal={signal}/>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
            <div className="content d-flex d-lg-none">
                <Errors status={status} whaleSignalsLength={whaleSignals.length}/>
            </div>
        </>
    );
}

export default WhaleSignalsTable;
