import React from "react";


const SignalPrice = ({signal}) => {

    function ShitcoinPrice(props) {
        if (props.value >= 0.00001) {
            return `$${parseFloat(props.value.toString().substring(0, 8))}`
        }

        const [,fraction] = props.value.toFixed(18).split(".")
        let prefixZeroes = 0
        while (fraction[prefixZeroes] === "0") prefixZeroes++

        const [,rest] = (parseFloat(fraction.substring(prefixZeroes, prefixZeroes + 4)) / 10000).toString().split(".")

        return <span>$0.0<sub>{prefixZeroes}</sub>{rest}</span>
    }

    return (
        <>
            <ShitcoinPrice value={signal.priceUsd}></ShitcoinPrice>
        </>
    );
};

export default SignalPrice;

