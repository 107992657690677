import React from "react"


const Score = ({value}) => {
    value = Number.isInteger(value) ? value : 0
    let valueText = Number.isInteger(value) ? String(value) : '???'
    let color = '#FFF'

    if (value > 0 && value <= 25) {
        color = '#FF453A'
    } else if (value > 25 && value <= 50) {
        color = '#FFD60A'
    } else if (value > 50) {
        color = '#32D74B'
    }

    return (
        <div type="button" style={{width: '44px', height: '44px', fontSize: '13px', fontWeight: 400}}
             data-bs-toggle="tooltip" data-bs-animation="false"
             data-bs-html="true" data-bs-title="
                This score reflects the token's potential risk and opportunity,
                ranging from 0 to 100. Key factors include liquidity changes,
                whale and fresh wallet activity, taxation impact, holder distribution, and security measures.
                A fresh and stable signal boosts the score, while rug pull indicators or price decreases lower it.
                Verify contract security and holder spread for a comprehensive understanding.
             ">
            <svg width="100%" height="100%" viewBox="0 0 40 40">
                <circle cx="20" cy="20" r="15.91549430918954" strokeWidth="3" fill="transparent" stroke={color}
                        style={{opacity: 0.15}}
                />
                <circle cx="20" cy="20" r="15.91549430918954" fill="transparent" strokeWidth="3" stroke={color}
                        strokeDasharray={`${value} ${100 - value}`} strokeDashoffset="25"
                />
                <g>
                    <text y="50%" transform="translate(0, 5)">
                        <tspan x="50%" textAnchor="middle" fill={color}>
                            {valueText}
                        </tspan>
                    </text>
                </g>
            </svg>
        </div>
    );
};

export default Score;