import React from "react";

const dollars = (number) => `$${number.toLocaleString()}`

const SignalMetrics = ({signal}) => {
    return (
        <>
            <div className="metrics d-none d-lg-block">
                <div className="grid">
                    <div className="row row-cols-2 row-gap-3">
                        <div className="col">
                            <div className="text-secondary">Mcap</div>
                            {dollars(signal.marketCapUsd)}
                        </div>
                        <div className="col">
                            <div className="text-secondary">Holders</div>
                            {signal.security.holderCount}
                        </div>
                        <div className="col">
                            <div className="text-secondary">Liquidity</div>
                            {signal.liquidity.currentAmount} {signal.liquidity.quoteSymbol}
                        </div>
                        <div className="col">
                            <div className="text-secondary">Volume</div>
                            {dollars(Math.round(signal.volumeUsd))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="metrics d-block d-sm-block d-lg-none">
                <h3>Metrics</h3>
                <div className="grid">
                    <div className="row g-2 g-md-2 g-sm-2">
                        <div className="col-4 col-md-4 d-flex align-items-stretch">
                            <div className="rounded-8 d-flex flex-fill align-items-center metric">
                                <div>
                                    <div className="text-secondary">Mcap</div>
                                    {dollars(signal.marketCapUsd)}
                                </div>
                            </div>
                        </div>

                        <div className="col-4 col-md-4 d-flex align-items-stretch ">
                            <div className="rounded-8 d-flex flex-fill align-items-center metric">
                                <div>
                                    <div className="text-secondary">Holders</div>
                                    {signal.security.holderCount}
                                </div>

                            </div>
                        </div>

                        <div className="col-4 col-md-4 d-flex align-items-stretch ">
                            <div className="rounded-8 d-flex flex-fill align-items-center metric">
                                <div>
                                    <div className="text-secondary">Volume</div>
                                    {dollars(Math.round(signal.volumeUsd))}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default SignalMetrics;

