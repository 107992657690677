import React from "react"
import clockGreyIcon from "../icons/clock-grey.svg";
import {msToString} from "../utils";
import infoIcon from "../icons/info.svg";
import timeUpdGreyIcon from "../icons/timeupd-grey.svg";


const SignalTime = ({signal, signalSpentTime}) => {
    return (
        <>
            <div className="d-none d-lg-block">
                <div className="time">
                    <img src={clockGreyIcon} alt="" width="16" className="align-text-top"/>
                    <span className="ps-1 pe-1">{signalSpentTime[signal.liquidity.pairAddress] && msToString(signalSpentTime[signal.liquidity.pairAddress].firstWhaleAt)}</span>
                    <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                          data-bs-html="true"
                          data-bs-title="Duration since the token's initial<br> appearance on this page">
                    <img src={infoIcon} alt={'Info'} width='16' className="align-text-top"/>
                </span>
                </div>
                <div className="mt-3">
                    <img src={timeUpdGreyIcon} alt="" width="16" className="align-text-top pr-1"/>
                    <span className="ps-1 pe-1">
                    {signalSpentTime[signal.liquidity.pairAddress] && msToString(signalSpentTime[signal.liquidity.pairAddress].lastUpdateAt)}
                </span>
                    <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                          data-bs-html="true"
                          data-bs-title="Duration since the last anomaly<br> was detected in the token">
                    <img src={infoIcon} alt={'Info'} width='16' className="align-text-top"/>
                </span>
                </div>
            </div>


            <div className="d-block d-sm-block d-lg-none times mt-4">
                <h3>Time</h3>
                <div className="row gx-2 gx-sm-0 gy-2 gy-sm-2 g-sm-2">
                    <div className="col-6 col-sm-12">
                        <div className="second-background rounded-8 py-3 px-3 time d-flex align-items-center">
                            <img src={clockGreyIcon} alt="" width="16" className="align-text-top"/>
                            <span className="ps-1 pe-1">
                                {signalSpentTime[signal.liquidity.pairAddress] && msToString(signalSpentTime[signal.liquidity.pairAddress].firstWhaleAt)}
                            </span>
                            <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                                  data-bs-html="true"
                                  data-bs-title="Duration since the token's initial<br> appearance on this page">
                                <img src={infoIcon} alt={'Info'} width='16' className="align-text-top" />
                            </span>
                        </div>
                    </div>
                    <div className="col-6 col-sm-12">
                        <div className="second-background rounded-8 py-3 px-3 time d-flex align-items-center">
                            <img src={timeUpdGreyIcon} alt="" width="16" className="align-text-top pr-1"/>
                            <span className="ps-1 pe-1">
                                {signalSpentTime[signal.liquidity.pairAddress] && msToString(signalSpentTime[signal.liquidity.pairAddress].lastUpdateAt)}
                            </span>
                            <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                                  data-bs-html="true"
                                  data-bs-title="Duration since the last anomaly<br> was detected in the token">
                                <img src={infoIcon} alt={'Info'} width='16' className="align-text-top"/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignalTime;

