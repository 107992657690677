import React from "react";

import whaleIcon from "../icons/emoji/whale.svg";
import starIcon from "../icons/emoji/star.svg";
import leafIcon from "../icons/emoji/leaf.svg";
import infoIcon from "../icons/info.svg";


const SignalAnomaly = ({signal}) => {
    return (
        <>
            <div className="d-flex flex-column anomality d-none d-lg-flex">
                <div><img src={whaleIcon} alt={''} width={16} className="me-2" /> {signal.whales} </div>
                <div><img src={starIcon} alt={''} width={16} className="me-2" /> {signal.kyc}</div>
                <div><img src={leafIcon} alt={''} width={16} className="me-2" /> {signal.freshWallets}</div>
            </div>

            <div className="d-block d-lg-none mt-4">
                <h3>Anomaly&nbsp;
                    <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                          data-bs-html="true" data-bs-title="
                                          <img src='/icons/emoji/whale.svg' width='12' /> big players <br>
                                          <img src='/icons/emoji/star.svg' width='12' /> influencer wallets <br>
                                          <img src='/icons/emoji/leaf.svg' width='12' /> new holders <br>
                                          <p>Quick growth in a young token <br>
                                          can indicate insider trading.</p>
                                      ">
                       <img src={infoIcon} alt={'Info'} width='16' />
                    </span>
                </h3>
                <div className="row g-2 g-sm-2">
                    <div className="col-4 col-sm-12">
                        <div className="rounded-8 py-2 px-3 second-background d-flex align-items-center justify-content-center anomaly">
                            <div>
                                <img src={whaleIcon} alt={''} width={16} className="me-2"/>
                            </div>
                            <div className="text-secondary flex-grow-1 d-none d-sm-block">
                                Whale:
                            </div>
                            <div>
                                {signal.whales}
                            </div>
                        </div>
                    </div>
                    <div className="col-4 col-sm-12">
                        <div className="rounded-8 py-2 px-3 second-background d-flex align-items-center justify-content-center anomaly">
                            <div>
                                <img src={starIcon} alt={''} width={16} className="me-2"/>
                            </div>
                            <div className="text-secondary flex-grow-1 d-none d-sm-block">
                                Stars:
                            </div>
                            <div>
                                {signal.kyc}
                            </div>
                        </div>
                    </div>
                    <div className="col-4 col-sm-12">
                        <div className="rounded-8 py-2 px-3 second-background d-flex align-items-center justify-content-center anomaly">
                            <div>
                                <img src={leafIcon} alt={''} width={16} className="me-2"/>
                            </div>
                            <div className="text-secondary flex-grow-1 d-none d-sm-block">
                                Fresh:
                            </div>
                            <div>
                                {signal.freshWallets}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignalAnomaly;

