import React from "react"
import loaderIcon from "../icons/loader.svg";
import loopIcon from "../icons/emoji/loop.svg";
import cryIcon from "../icons/emoji/cry.svg";

const Errors = ( { status, whaleSignalsLength} ) => {
    return (
        <>
            {status === 'loading' &&
            <div className="loader-container">
                <div>
                    <img src={loaderIcon} alt="Loading..." width="48"/>
                </div>
                <div>Loading...</div>
            </div>
            }

            {status === 'ok' && !whaleSignalsLength &&
            <div className="empty-data-contrainer">
                <div>
                    <img src={loopIcon} alt={'No anomalies'} width='48'/>
                </div>
                <div>
                    No anomalies yet, but they'll come soon
                </div>
            </div>
            }

            {status === 'error' &&
            <div className="error-contrainer">
                <div>
                    <img src={cryIcon} alt={'something went wrong'} width='48'/>
                </div>
                <div>
                    Oops, something went wrong...
                </div>
                <div>
                    <a href={'/'} className="btn btn-secondary btn-sm">
                        Update page
                    </a>
                </div>
            </div>
            }
        </>
    );
};

export default Errors;
