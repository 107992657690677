import React, {useState} from "react"
import WhaleSignalsPanel from "./components/WhaleSignalsPanel"
import Header from "./components/Header"
import Footer from "./components/Footer"

import alphaIcon from "./icons/alpha.png"
import maestroIcon from "./icons/maestro-small.svg"
import unibotIcon from "./icons/unibot-small.svg"
import bananagunIcon from "./icons/bananagun-small.svg"

function App() {
    const botsList = [
        {tg_id: 'alpha_web3_bot', name: 'Alpha', icon: alphaIcon, disabled: false},
        {tg_id: 'maestro', name: 'Maestro Bot', icon: maestroIcon, disabled: false},
        {tg_id: 'unibotsniper_bot', name: 'UNIBOT', icon: unibotIcon, disabled: false},
        {tg_id: 'BananaGunSniper_bot', name: 'BananaGun', icon: bananagunIcon, disabled: false},
    ]

    const [bot, setBot] = useState(botsList[0])

    return (
            <div className="container custom-container min-vh-100 d-flex flex-column ">
                <Header bot={bot} setBot={setBot} botsList={botsList} />

                <WhaleSignalsPanel bot={bot} />


                <Footer />
            </div>
    );
}

export default App;

