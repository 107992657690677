import React, {useState} from "react"

import arrowDownIcon from '../icons/down.svg'
import arrowUpIcon from '../icons/up.svg'
import tickIcon from '../icons/tick.svg'
import closeIcon from '../icons/close.svg'
import loopIcon from '../icons/emoji/loop.svg'
import startFaceIcon from '../icons/emoji/start-face.svg'
import whaleIcon from '../icons/emoji/whale.svg'
import starIcon from '../icons/emoji/star.svg'
import chartIcon from '../icons/emoji/chart.svg'
import infoGreyIcon from "../icons/info-grey.svg"


const Header = ({bot, setBot, botsList}) => {
    const [selectorOpened, setSelectorOpened] = useState(false)

    return (
        <>
            <div className="d-none d-md-block">
                <nav className="navbar">
                    <div className="container-fluid">
                        <div className="d-flex align-items-center">
                            <h1 className="m-0">Whale signals</h1>
                            <div className="vertical-line">|</div>
                            <div className="align-self-center">
                                <button type="button" className="btn btn-primary btn-sm"
                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                >How it Works</button>
                            </div>
                        </div>
                        <div className="d-flex align-items-center gap-2 snipe-block">
                            <div className="">Snipe Execution
                                <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                                      data-bs-html="true" data-bs-title="Here you can select the provider that will be activated when you press the snipe button">
                                    <img src={infoGreyIcon} alt={'Info'} width='16' />
                            </span>
                            </div>
                            <div>
                                <button type="button" className="btn btn-bot"
                                        onClick={() => setSelectorOpened(!selectorOpened)}
                                >
                                    <img src={bot.icon} height={24} width={24} alt={''} />
                                    {bot.name}
                                    <img src={selectorOpened ? arrowUpIcon : arrowDownIcon} width={24} className="float-end" alt={''} />
                                </button>

                                {!!selectorOpened &&
                                <div style={{ position: "absolute", zIndex: '1000', marginTop: '8px'}}>
                                    <div className="btn-group-vertical" role="group" aria-label="Vertical button group">
                                        {botsList.map(b =>
                                            <button key={b.tg_id} type="button" className="btn btn-inner" disabled={b.disabled}
                                                    onClick={() => {setBot(b); setSelectorOpened(false)}}
                                            >
                                                <img width="24" height="24" src={b.icon} alt={''}/>
                                                <span>{b.name}</span>
                                                {b.tg_id === bot.tg_id &&
                                                <img src={tickIcon} width={16} alt={''} className="float-end tick" />
                                                }
                                            </button>
                                        )}
                                    </div>
                                </div>
                                }

                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <div className="d-block d-md-none">
                <nav className="navbar">
                    <div className="container-fluid">
                        <div className="d-flex flex-row align-items-center flex-grow-1">
                            <h1 className="m-0">Whale signals</h1>
                            <div className="ms-auto">
                                <button type="button" className="btn btn-primary btn-sm"
                                        data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                                >How it Works</button>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="d-flex align-items-center gap-2 snipe-block">
                    <div className="">Snipe Execution
                        <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                              data-bs-html="true" data-bs-title="Here you can select the provider that will be activated when you press the snipe button">
                                    <img src={infoGreyIcon} alt={'Info'} width='16' />
                            </span>
                    </div>
                    <div className="ms-auto">
                        <button type="button" className="btn btn-bot"
                                onClick={() => setSelectorOpened(!selectorOpened)}
                        >
                            <img src={bot.icon} height={24} width={24} alt={''} />
                            {bot.name}
                            <img src={selectorOpened ? arrowUpIcon : arrowDownIcon} width={24} className="float-end" alt={''} />
                        </button>

                        {!!selectorOpened &&
                        <div style={{ position: "absolute", zIndex: '1000', marginTop: '8px'}}>
                            <div className="btn-group-vertical" role="group" aria-label="Vertical button group">
                                {botsList.map(b =>
                                    <button key={b.tg_id} type="button" className="btn btn-inner" disabled={b.disabled}
                                            onClick={() => {setBot(b); setSelectorOpened(false)}}
                                    >
                                        <img width="24" height="24" src={b.icon} alt={''}/>
                                        <span>{b.name}</span>
                                        {b.tg_id === bot.tg_id &&
                                        <img src={tickIcon} width={16} alt={''} className="float-end tick" />
                                        }
                                    </button>
                                )}
                            </div>
                        </div>
                        }

                    </div>
                </div>
            </div>
            <div className="modal fade modal-lg" id="staticBackdrop" data-bs-keyboard="false"
                 tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="staticBackdropLabel">Whats interesting happen here?</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    style={{ background: `url(${closeIcon}) no-repeat center center`}}>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="items">
                                <div className="item">
                                    <div><img src={loopIcon} width="24" alt={''} /></div>
                                    <div>Get insights and make XX's profit by peeking behind the market curtains.</div>
                                </div>
                                <div className="item">
                                    <div><img src={startFaceIcon} width="24" alt={''} /></div>
                                    <div>Here, you'll discover the hottest tokens, chosen based on the activity of major players.</div>
                                </div>
                                <div className="item">
                                    <div><img src={whaleIcon} width="24" alt={''} /></div>
                                    <div>As soon as the whales buy a tokens, it will appear here, and you can join it through Maestro bot by pressing Snipe button. It couldn't be easier!</div>
                                </div>
                                <div className="item">
                                    <div><img src={starIcon} width="24" alt={''} /></div>
                                    <div>This means you'll be aware of potentially growing coins before others.</div>
                                </div>
                                <div className="item">
                                    <div><img src={chartIcon} width="24" alt={''} /></div>
                                    <div>Along with key metrics for each asset and integration with the trading bot Maestro, this will help you maximize profits in DEX trading.</div>
                                </div>
                            </div>
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                                aria-expanded="false" aria-controls="flush-collapseOne">
                                            Okay, how do I snipe?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" className="accordion-collapse collapse"
                                         data-bs-parent="#accordionFlushExample">
                                        <div className="accordion-body">
                                            <ol>
                                                <li>
                                                    Choose a provider for executing your trades, it could be Maestrobot or Unibot; we will add others soon
                                                </li>
                                                <li>
                                                    Review the tokens listed and select those that seem promising to you
                                                </li>
                                                <li>
                                                    Click the 'Snipe' button, this will redirect you to the trading bot where you can set values for automatic buying and selling of this token at specific rates
                                                </li>
                                                <li>
                                                    Experiment with the values and further study of tokens, maximize profit, and share our platform for its development. Happy hunting!
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>


    );
};

export default Header;
