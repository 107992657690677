const MONTH_NAMES = [
    "Jan", "Feb", "March", "Apr", "May", "June",
    "July", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const twoDigits = (value) => value > 9 ? value : `0${value}`

export function msToString(ms) {
    const hours = Math.floor(ms / 1000 / 60 / 60);
    const minutes = Math.floor(ms / 1000 / 60 % 60);
    const seconds = Math.floor(ms / 1000 % 60);

    return `${hours}h ${minutes}m ${String(seconds).padStart(2, '0')}s`;
}

export function toDateTime(dateString) {
    const date = new Date(dateString)
    return `${date.getDate()} ${MONTH_NAMES[date.getMonth()]} ${twoDigits(date.getHours())}:${twoDigits(date.getMinutes())}`
}

export function yesNo(value) {
    return value ? "Yes" : "No"
}

export function diffPercents(percents) {
    const sign = percents > 0 ? '+' : ''
    return `${sign}${percents.toFixed(2)}%`
}
