import React from "react"

import LogoCloudDevs from '../icons/LogoCloudDevs.svg'
import CloudDevs from '../icons/CloudDevs.svg'


const Footer = () => {
    return (
        <footer className="text-white text-center py-4 py-sm-10 mx-auto w-100 ">
            <a href="https://clouddevs.pro/" target="_blank" rel="noreferrer" className="flex text-decoration-none color-white">
                <span>Powered by</span>
                <img src={LogoCloudDevs} width="59" height="24" alt="CloudDevs Logo" className="align-bottom px-3"/>
                <img src={CloudDevs} width="83" height="13"  alt="[CloudDevs]" className="mb-1 align-text-top"/>
            </a>
        </footer>
    );
};

export default Footer;
