import React from "react";
import {diffPercents} from "../utils";
import infoIcon from "../icons/info.svg";


const SignalChange = ({signal}) => {
    return (
        <>
            <div className="d-flex flex-column d-sm-block d-lg-flex change">
                <h3 className="d-lg-none">Change</h3>
                <div className="columns rounded-8">
                    <div className="row gx-2 gx-sm-2 gx-md-2 gy-lg-3 g-lg-0">
                        <div className="col-6 col-lg-12 ">
                            <span className="ps-1 pe-1">Current</span>
                            <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                                  data-bs-title="Current - Current price change of the token since the start of tracking on Whale Signals">
                                <img src={infoIcon} alt={'Info'} width='16' className="align-text-top"/>
                            </span>
                            <br/>
                            <div className={(signal.currentProfitPercent) < 0 ? "change-minus mt-1" : "change-plus mt-1"}>
                                {diffPercents(signal.currentProfitPercent)}
                            </div>
                        </div>

                        <div className="col-6 col-lg-12">
                            <span className="ps-1 pe-1">Max</span>
                            <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                                  data-bs-title="Max - Maximum price change since the token was tracked on Whale Signals. Those who bought the token as soon as it appeared here could have received this profit">
                                <img src={infoIcon} alt={'Info'} width='16' className="align-text-top"/>
                            </span>
                            <br/>
                            <div className={(signal.maxProfitPercent) < 0 ? "change-minus mt-1" : "change-plus mt-1"}>
                                {diffPercents(signal.maxProfitPercent)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
);
};

export default SignalChange;

