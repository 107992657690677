import React from "react";

const SignalInitialLiquidity = ({signal}) => {

    return (
        <>
            <div className="d-none d-lg-block">
                {signal.liquidity.initialAmount} {signal.liquidity.quoteSymbol}
            </div>

            <div className="d-none d-sm-block d-lg-none liquidity mt-4">
                <h3>Initial Liquidity & Price</h3>
                <div className="d-flex second-background align-items-center rounded-8 mb-2 py-3 px-3 liq">
                    <div className="name flex-grow-1">
                        Initial liquidity:
                    </div>
                    <div>
                        {signal.liquidity.initialAmount} {signal.liquidity.quoteSymbol}
                    </div>
                </div>
                <div className="d-flex second-background align-items-center rounded-8 mb-2 py-3 px-3 liq">
                    <div className="name flex-grow-1">
                        Current Liquidity:
                    </div>
                    <div>
                        {signal.liquidity.currentAmount} {signal.liquidity.quoteSymbol}
                    </div>
                </div>
            </div>

            <div className="d-block d-sm-none liquidity mt-4">
                <div className="row gx-2">
                    <div className="col-6">
                        <h3>
                            Initial liquidity:
                        </h3>
                        <div className="second-background rounded-8 py-3 px-3">
                            {signal.liquidity.initialAmount} {signal.liquidity.quoteSymbol}
                        </div>
                    </div>
                    <div className="col-6">
                        <h3>
                            Current Liquidity:
                        </h3>
                        <div className="second-background rounded-8 py-3 px-3">
                            {signal.liquidity.currentAmount} {signal.liquidity.quoteSymbol}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignalInitialLiquidity;

