import React from "react";
import activeIcon from "../icons/active.svg";
import inactiveIcon from "../icons/inactive.svg";


const SignalSecurity = ({signal}) => {
    return (
        <>
            <div className="d-block d-sm-block mt-4">
                <h3 className="d-lg-none mb-3">Security</h3>
                <ul className="security">
                    <li>
                        <img
                            src={signal.security.buyTax <= 10 && signal.security.sellTax <= 10 ? activeIcon : inactiveIcon}
                            width={8} alt=""/>
                        Tax: <span className="plus">  B: {signal.security.buyTax}% </span>
                        <span className="minus"> S: {signal.security.sellTax}% </span>
                    </li>
                    <li>
                        <img src={signal.security.lockedLpPercent > 90 ? activeIcon : inactiveIcon}
                             width={8} alt=""/>
                        <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                              data-bs-title={`${signal.security.lockedLpPercent}%`}>
                                                Liquidity lock
                                            </span>
                    </li>
                    <li>
                        <img src={signal.security.isHoneypot ? inactiveIcon : activeIcon}
                             width={8} alt=""/>
                        Honeypot
                    </li>
                    <li>
                        <img src={signal.security.contractVerified ? activeIcon : inactiveIcon}
                             width={8} alt=""/>
                        Contract Verified
                    </li>
                    <li><img src={signal.security.contractRenounced ? activeIcon : inactiveIcon}
                             width={8} alt=""/>
                        Contract Renounced
                    </li>
                    <li>
                        <img
                            src={signal.security.creatorBalancePercent < 5 ? activeIcon : inactiveIcon}
                            width={8} alt=""/>
                        Owner Balance &#60; 5%
                    </li>
                </ul>
            </div>
        </>
    );
};

export default SignalSecurity;

