import React, {useState} from "react";
import Score from "./Score";
import chartIcon from "../icons/chart.svg";
import copyIcon from "../icons/copy.svg";


const SignalName = ({signal, bot}) => {
    const shortAddress = (address) => address.substring(0, 8);
    const [copiedAlert, setCopiedAlert] = useState({show: false, error: false})

    const copy = (value) => {
        try {
            navigator.clipboard.writeText(value.toString())
                .then(() => {
                    setCopiedAlert({show: true, error: false});
                    setTimeout(() => setCopiedAlert({show: false, error: false}), 3000)
                })
        }
        catch (_) {
            setCopiedAlert({show: true, error: true});
            setTimeout(() => setCopiedAlert({show: false, error: true}), 3000)
        }
    }


    return (
        <>
            <div className="d-none d-lg-block">
                <div className="d-flex gap-2 token">
                    <div>
                        <Score value={signal.score}/>
                    </div>
                    <div className="flex-grow-1">
                        <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                              data-bs-html="true" data-bs-title={signal.token.name}>{signal.token.symbol}</span>
                        <div>
                            {shortAddress(signal.token.address)}
                            <img src={chartIcon} alt="Chart" width="16" role="button"
                                 className="float-end"
                                 onClick={() => window.open(`https://www.dextools.io/app/en/ether/pair-explorer/${signal.liquidity.pairAddress}`, '_blank').focus()}/>
                            <img src={copyIcon} alt="Copy" width="16" role="button"
                                 onClick={() => copy(signal.token.address)}
                                 className="float-end"/>

                        </div>
                    </div>
                </div>
                <div className="d-grid mt-3">
                    <a href={`https://t.me/${bot.tg_id}?start=snipe-${signal.token.address}`}
                       target="_blank" rel="noreferrer"
                       className="btn btn-xs btn-secondary btn-snipe">Snipe</a>
                </div>
            </div>

            <div className="d-flex d-sm-flex d-md-flex d-lg-none pb-0 pb-sm-4 pb-md-6 border-name ">
                <div className="d-flex gap-2 token flex-grow-1">
                    <div>
                        <Score value={signal.score}/>
                    </div>
                    <div className="">
                        <span type="button" data-bs-toggle="tooltip" data-bs-animation="false"
                              data-bs-html="true" data-bs-title={signal.token.name}>{signal.token.symbol}</span>
                        <div>
                            {shortAddress(signal.token.address)}
                            <img src={chartIcon} alt="Chart" width="16" role="button"
                                 onClick={() => window.open(`https://www.dextools.io/app/en/ether/pair-explorer/${signal.liquidity.pairAddress}`, '_blank').focus()}/>
                            <img src={copyIcon} alt="Copy" width="16" role="button"
                                 onClick={() => copy(signal.token.address)}
                                 />
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <a href={`https://t.me/${bot.tg_id}?start=snipe-${signal.token.address}`}
                       target="_blank" rel="noreferrer"
                       className="btn btn-xs btn-secondary btn-snipe">Snipe</a>
                </div>
            </div>

            {copiedAlert.show &&
            <div className={copiedAlert.error ? 'err-message' : 'success-message'}>
                {copiedAlert.error ? 'Error copying' : 'Address Copied'}
            </div>
            }
        </>
    );
};

export default SignalName;

